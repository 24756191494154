const data ={
    "seccion" : [
        {
            "id":"/inicio",
            "info":['"Conozca los servicios que ofrecemos para brindarle la mejor atención"', ""]
        },
        {
            "id":"/impositivo",
            "info": [
                "Asesoramiento integral a monotribustistas y Responsables inscriptos.",
                "Asesoramiento impositivo en general, facturación, planes de pago y retenciones.",
                "Asistencia en inspecciones de Organismos de Fiscalización.",
                "Declaraciones juradas y liquidación de impuestos nacionales, provinciales y municipales.",
                "Tramitación de altas y bajas ante los organismos.", 
                "Organización contable en empresas",
                
            ],
            "icon": "icon-stats-dots"
        },
        {
            "id":"/contable",
            "info":[    
                "Asesoramiento integral contables y administrativo para empresas y personas.",
                "Preparación de estados contables.",
                "Elaboración del planes de cuentas personalizadas.",
                "Registraciones contables.",
                "Elaboración del planes de cuentas personalizadas.",
                "Reorganización de circuitos y documentación.",
                "Diseño e implementación de manuales de procedimientos, instructivos.",
                "Asistencia  y asesoramiento en reestructuración y reorganización de empresas.",
                "Diseño e implementación de programas de mejora continua."
            ],
            "icon": "icon-library"
        },
        {
            "id":"/asesoramiento",
            "info":[
                "Liquidación de sueldos.",
                "Asesoramiento y evaluación de las distintas formas de contratación laboral.",
                "Asesoramiento sobre diversas situaciones laborales sobre administración de personal."
            ],
            "icon": "icon-briefcase"

        },
        {
            "id":"/nuevos",
            "info":[
                "Análisis de planes de inversión.",
                "Confección de flujos de fondos.",
                "Asistencia para la creación de nuevos negocios administrativa, contable e impositivamente.",
                "Planes de acción, evaluaciones de negocios.",
                "Análisis financiero de capital requerido."
            ],
            "icon": "icon-user-tie"

        },
    ]
}

export default data;