import React,{useState} from 'react';
import Layout from '../components/layout';
import BannerServ from '../components/servicios/bannerServ';
import Services from '../components/servicios/services';
import info from '../components/servicios/info'

const Servicios = () => {
    const {seccion} = info;
    const [servicios, setServicios] = useState(seccion[1]);
   
    
    
    return (  
        <Layout>
            <BannerServ/>
            
            <Services servicios={servicios} setServicios={setServicios} seccion={seccion}/>
        </Layout>
    );
}


export default Servicios;