import React from 'react';
import styled from '@emotion/styled';


const Descrip = styled.div`
    transition: all 0.3s linear;
    margin:auto;
    position:absolute;
    width:100%;
    top: 0;
    left: 0;
    right: 0;
    z-index:100;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:1.8rem;
   
    @media (max-width:1024px){
        margin:.5rem auto;
    }
    @media (max-width:480px){
        width:100%;
        text-align:left;
    }

    ul{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        text-align:center;
        margin-top:${props => props.tamaño === 1 ? '10rem' : 
                          props.tamaño === 6 ? '8rem' : 
                          props.tamaño === 9 ? '1rem' : 
                          props.tamaño === 3 ? '11rem' : 
                          props.tamaño === 5 ? '9rem' :
                          props.tamaño === 2 ? '12rem' : '0rem'};
        @media (max-width:480px){
            margin-top:${props => props.tamaño === 1 ? '10rem' : 
                          props.tamaño === 6 ? '0' : 
                          props.tamaño === 9 ? '0' : 
                          props.tamaño === 3 ? '0' : 
                          props.tamaño === 5 ? '0' :
                          props.tamaño === 2 ? '12rem' : '0rem'};
        }
        
        li{
            font-size:${props => props.id === '/inicio'  ? '3rem' : '1.8rem'};
            font-style:${props =>props.id === '/inicio' ? 'italic' : 'normal'};
            margin:.5rem;
            width:100%;
            @media ( max-width:1024px){
                font-size:${props => props.id === '/inicio'  ? '3rem' : '1.7rem'};
            }
            @media ( max-width:480px){
                font-size:${props => props.id === '/inicio'  ? '2rem' : '1.6rem'};
            }
        }
        span{
            display:block;
            font-size:6rem;
            margin-bottom:2rem;
        }
        @media (max-width:1024px){
            font-size:1.6rem;
        }
    }
}
    
`



const Descripcion = ({servicios}) => {
    
    const {info,icon,id} = servicios;
    
    const tamaño = info.length;
  
   
    return(
            <Descrip tamaño={tamaño}  id={id}>
                
                <ul> <span   className={icon}></span>
                
                    <li>{info[0]}</li>
                    <li>{info[1]}</li>
                    <li>{info[2]}</li>
                    <li>{info[3]}</li>
                    <li>{info[4]}</li>
                    <li>{info[5]}</li>
                    <li>{info[6]}</li>
                    <li>{info[7]}</li>
                    <li>{info[8]}</li>
                    
                </ul>
            </Descrip>
        )
    
    

    
}
 
export default Descripcion;