import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from '@emotion/styled';



const ImageBackground = styled(BackgroundImage)`
        height:300px;      
        margin-top:180px;
        @media (max-width:768px){
            height:200px;
            margin-top:200px;
        }
`

const TextoImagen =styled.div`

        background-image:linear-gradient(to top, rgba(34,49,63,.7),rgba(34,49,63,.7));
        color:#f9f7f7;
        height:100%;
        display:flex;
        flex-direction:column;
        flex:1;
        align-items:center;
        justify-content:center;
        h2{
          
          font-size:6rem;
          font-weight:bold;
          }
        p{
          font-size:2.5rem;
        }
`

const BannerServ = () => {
   

    const {image} = useStaticQuery(graphql`
    query{
        image: file(relativePath: {eq:"banner.jpg"}){
        sharp:childImageSharp{
          fluid{
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `)
    
    return ( 
        <ImageBackground tag='section' fluid={image.sharp.fluid} fadeIn='soft'>
            <TextoImagen>
                <h2>Servicios </h2>
                <p>Asesoramiento y Gestion</p>

            </TextoImagen>

        </ImageBackground>
     );
}
 
export default BannerServ;