import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import scrollTo from 'gatsby-plugin-smoothscroll';
import {useMediaQuery} from 'react-responsive';



const Btn = styled.a`
    width:100%;
    padding:1rem;
    display:block;
    margin:1.5rem;
    background-color:${props => props.zona==='/impositivo' ? '#dbe2ef' : '#3f72af' };
    color:${props => props.zona==='/impositivo' ? '#112d4e' : '#f9f7f7' };
    transition: all 1s;
    border-radius:2rem;
    outline: none;
    text-align:center;
    font-weight: ${props => props.zona ==='/impositivo' ? 'bold' : 'normal'};
    cursor:pointer;
    :first-of-type{
            margin-left:0;
    }
    
    :active{
        background-color:white;
        color:#112d4e;
        font-weight:bold;
        
    }
    :hover{
        background-color:#dbe2ef;
        color:#3f72af;
    }
   
`
const Btn1 = styled.a`
    width:100%;
    padding:1rem;
    display:block;
    margin:1.5rem;
    background-color:${props => props.zona==='/contable' ? '#dbe2ef' : '#3f72af' };
    color:${props => props.zona==='/contable' ? '#112d4e' : '#f9f7f7' };
    transition: all 1s;
    border-radius:2rem;
    outline: none;
    text-align:center;
    font-weight: ${props => props.zona ==='/contable' ? 'bold' : 'normal'};
    cursor:pointer;
    :first-of-type{
            margin-left:0;
    }
    
   
    :hover{
        background-color:#dbe2ef;
        color:#3f72af;
    }
   
`
const Btn2 = styled.a`
    width:100%;
    padding:1rem;
    display:block;
    margin:1.5rem;
    background-color:${props => props.zona==='/asesoramiento' ? '#dbe2ef' : '#3f72af' };
    color:${props => props.zona==='/asesoramiento' ? '#112d4e' : '#f9f7f7' };
    transition: all 1s;
    border-radius:2rem;
    outline: none;
    text-align:center;
    font-weight: ${props => props.zona ==='/asesoramiento' ? 'bold' : 'normal'};
    cursor:pointer;
    :first-of-type{
            margin-left:0;
    }
    
  
    :hover{
        background-color:#dbe2ef;
        color:#3f72af;
    }
   
`
const Btn3 = styled.a`
    width:100%;
    padding:1rem;
    display:block;
    margin:1.5rem;
    background-color:${props => props.zona==='/nuevos' ? '#dbe2ef' : '#3f72af' };
    color:${props => props.zona==='/nuevos' ? '#112d4e' : '#f9f7f7' };
    font-weight: bold;
    transition: all 1s;
    border-radius:2rem;
    outline: none;
    text-align:center;
    font-weight: ${props => props.zona ==='/nuevos' ? 'bold' : 'normal'};
    cursor:pointer;
    :first-of-type{
            margin-left:0;
    }
    
    :active{
        background-color:white;
        color:#112d4e;
        font-weight:bold;
        
    }
    :hover{
        background-color:#dbe2ef;
        color:#3f72af;
    }
   
`


const Navi = ({servicios,setServicios,seccion})=>{
   
    const {id} = servicios;
   

    const isMobile = useMediaQuery({
        query : '(max-device-width : 480px)'
    })

    
    const changeService = value =>{
        
        
        setServicios(seccion[value]);
        if(isMobile){scrollTo('#seccion');}
        
    }
    
    return ( 
        <>
        <ul   css={css`
            display:flex;
            justify-content:space-evenly;
            @media (max-width:480px){
                flex-direction:column;
            }
            li{
                margin:.5rem;
                width:25%;
                @media ( max-width:480px){
                    width:100%;
                    margin:.2rem;
                }
                :first-of-type{
                    margin-left:0;
                }
            }
        `}>
            <li><Btn zona={id} onClick={()=> changeService(1)} >Impostivo</Btn></li>
            <li><Btn1  zona={id} onClick={()=> changeService(2)}>Contable y Administrativo</Btn1></li>
            {isMobile && <span id='seccion'></span>}
            <li><Btn2 zona={id} onClick={()=> changeService(3)}>Laboral Y Previsional</Btn2></li>
            
            <li><Btn3 zona={id} onClick={()=> changeService(4)}>Nuevos Negocios</Btn3></li>
        </ul>
        
        </>
     );
}
 
export default Navi;
