import React from 'react';
import styled from '@emotion/styled';
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Navi from './navi';

import Descripcion from './descripcion';

const Serv =styled.div`
    margin:3rem auto;
    display:flex;
    align-items:center;
    max-width:1300px;
    padding:2rem;
  
  
    flex-direction:column-reverse;
    @media ( max-width:480px){
        flex-direction:column-reverse;
        
    }

`

const List = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    height:500px;
    border: 5px solid #dbe2ef;
    border-radius: 1rem;
       @media ( max-width:480px){
        margin:2rem auto;
        height:${props => props.tamaño === 2 ? '500px' : 
                          props.tamaño === 6 ? '550px' : 
                          props.tamaño === 9 ? '730px' : 
                          props.tamaño === 3 ? '370px' : 
                          props.tamaño === 5 ? '420px' : '650px'
        };
                          
    }
`

const Menu = styled.div`
    
    width:100%;
`


const Services = ({servicios, setServicios,seccion}) => {
   
     const {info} = servicios;
     const tamaño = info.length;
     
    return ( 
        <section>
            <Serv>
                <List tamaño={tamaño}>
                
                
                    
                    <TransitionGroup  className="card-container">
                        <CSSTransition
                            key={servicios.id}
                            timeout={800}
                            classNames="fade"
                        >
                                <Descripcion  servicios={servicios} setServicios={setServicios} />
                        </CSSTransition>
                    </TransitionGroup>
                    
                </List>
                <Menu>
                    <Navi servicios={servicios} setServicios={setServicios} seccion={seccion} />
                </Menu>
            </Serv>
        </section>
     );
}
 
export default Services
